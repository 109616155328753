
.divider-text-far-left.day::before {
    width: 0 !important;
}

.divider-text-far-left.day span {
    padding-left: 0 !important;
    width: 83px !important;
}

.divider-text-far-left.day span p {
    text-align: left !important;
}

.divider-text-far-left::before {
    width: 2% !important;
}

.event-overview-infinite-scrolling-box {
    position: relative;
    background: transparent !important;
    overflow: auto;
    max-width: 1200px;
    max-height: 100%;
    width: 100dvw;
    height: 100dvh;
    margin: 0 auto;
}

.event-overview-infinite-scrolling-box::-webkit-scrollbar {
    display: none;
}

.event-overview-time-divider {
    position: absolute;
    left: 0;
    height: 2rem;
    width: 100%;
}

.event-overview-time-divider div div {
    margin: auto;
}
