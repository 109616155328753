.image-selector {
    display: flex !important;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 10px;
}

.image-selector input {
    display: none;
}

.image-selector .image-display {
    max-width: 100dvw;
    height: 200px;
    object-fit: contain
}

