
#app {
    height: 100dvh;
    width: calc(100dvw - 20px);
    margin: 0 auto;
}

.MuiDialog-root.MuiModal-root {
    z-index: 100000;
}
