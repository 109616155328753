.event-overview-item .title {
    text-align: left;
    font-weight: 700;
    font-size: 0.75rem;
    text-shadow: black 0 0 2px;
}

.event-overview-item .inner-container {
    margin: 2px;
    width: calc(100% - 4px);
    height: calc(100% - 4px);
    box-shadow: #00000055 0 0 6px 6px;
    border-radius: 6px;
    cursor: pointer;
    padding: 0 0.3rem 0.3rem;
    background-color: color-mix(in srgb, var(--event-color) 70%, transparent 30%);
}

.event-overview-item.selected .inner-container{
    background-color: var(--event-color);
}

.event-overview-item.selected {
    z-index: 500;
}

.event-overview-item {
    transition: all 0.2s ease-in-out;
    transform: scale(1);
}

.event-overview-item:hover {
    transform: scale(1.01);
    z-index: 1000;
}

