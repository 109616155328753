
.event-edit-container {
    position: absolute;
    max-width: none !important;
    height: 100dvh;
    width: 100dvw;
    top: 0;
    left: 10px;
    background-color: #121212;
    z-index: 100000;
    transition: transform 0.5s;
    transform-origin: 120dvw -10dvh;
    transform: scale(1);
}

.event-edit-container.hidden {
    transform: scale(0);
}

.event-edit-form {
    display: flex !important;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    gap: 10px;
}

.event-edit-wrap-row {
    display: flex !important;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 10px;
    margin-bottom: 10px ;
}

.event-edit-date-select-outer-container {
    position: relative;
    transition: height 0.3s;
    height: 60px;
}

.event-edit-date-select-outer-container.large {
    height: 130px;
}

.event-edit-date-select-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex !important;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
}

.event-edit-bottom-buttons {
    margin-top: 30px;
    justify-content: center;
    align-items: center;
    display: flex !important;
    gap: 10px;
}

.event-edit-bottom-buttons button {
    width: 300px;
}

.event-edit-close-button {
    position: absolute !important;
    height: 40px;
    width: 40px;
    min-width: 40px !important;
    top: 10px;
    right: 10px;
}