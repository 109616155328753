.calendar-navbar {
    position: fixed;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    backdrop-filter: blur(10px);
    background-color: rgba(0, 0, 0, 0.2);
    height: 60px;
    color: white;
    z-index: 10000;
    width: 100%;
    margin: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
}

.calendar-navbar .nav-element {
    width: calc(100% / 3);
    display: flex;
    justify-content: center;
    align-items: center;
}

.calendar-navbar .nav-element:first-child {
    justify-content: left;
}

.calendar-navbar .nav-element:last-child {
    justify-content: right;
}

.calendar-date-select-container {
    position: absolute;
    width: 100dvw !important;
    max-width: none !important;
    top: 30%;
    transform: translateY(-50%);
}
